import axios from "axios";
import authHeader from "./auth-header";
import appSettings from "../appsettings.json";

const API_URL = appSettings.SERVICE_URL + "post";

const getAllPublicPosts = () => {
  return axios.get(API_URL);
};

const getAllPrivatePosts = () => {
  return axios.get(API_URL + "/private", { headers: authHeader() });
};

const postService = {
  getAllPublicPosts,
  getAllPrivatePosts,
};

export default postService;
