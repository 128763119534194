import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/auth.service";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [OTP, setOtp] = useState("");
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState('');
  const [stepLogin, setStep] = useState('');
  const [remember, setRemember] = useState(false);

  const navigate = useNavigate();

  const toggleRemember = async (e) => {
    setRemember(!remember);
  };

  const handleLogin = async (e) => {
    
    e.preventDefault();
    try {
      if (remember) {
        await AuthService.resetPwd(email).then(
          (response) => {
            if (response) {
              setErrMsg("Controlla la tua mail");
              setRemember(false);
            } else {
              setErrMsg("Richiesta non riuscita");
            }
          }

        );
      } else {
        await AuthService.login(email, password, OTP).then(
          (loginResult) => {
            console.log(loginResult);
            if (loginResult === 2) {
              navigate("/home");
              window.location.reload();
            } else if (loginResult === 1) {
              setStep(loginResult);
            } else {
              throw("Errore al login");
            }
            
          },
          (error) => {
            setErrMsg(error);
          }
        );
      }
      

    } catch (err) {
      if (!err?.response || !err.response?.data) {
        setErrMsg('No Server Response');
      } else {
          setErrMsg(err.response?.data.message);
      } 
    }
  };

  return (
    <section>
      <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
      <h3>{t("lgn_login")}</h3>
      <form onSubmit={handleLogin}>
      {
        stepLogin === 1 ? (
          <>
            {t("lgn_otp_req")}
            OTP required
            <label htmlFor="OTP">{t("lgn_otp_lbl")}</label>
            <input
              id="OTP"
              type="password"
              placeholder="password"
              value={OTP}
              onChange={(e) => setOtp(e.target.value)}
            />
          </>
        ) : (
          <>
          <label htmlFor="username">Username:</label>
          <input
            id="username"
            type="text"
            placeholder="username"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          {!remember ? <>
            <label htmlFor="password">Password:</label>
          <input
            id="password"
            type="password"
            placeholder="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          </> : <> </>}
          </>
        )
      }
      
      
        <button className="button-77" type="submit">
          {remember ? t("btn_snd_rich") : t("lgn_btn_login")}
          </button>
      </form>
      <p>
          <span className="line">
              {/*put router link here*/}
              <button 
                onClick={toggleRemember}
                className="btn btn-link"
              >
                {remember ? t("lgn_lnk_backlogin") : t("lgn_lnk_remember")}
              </button>
          </span>
      </p>
    </section>
  );
};

export default Login;
