import React, { useState, useEffect } from "react";
import PostService from "../services/post.service";
import PostCarousel from "../components/PostCarousel";
import styles from './Cards.css';
const Home = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    PostService.getAllPublicPosts().then(
      (response) => {
        setPosts(response.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  return (
    <PostCarousel posts={posts} />
  );
};

export default Home;
