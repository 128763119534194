import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import AuthService from "../services/auth.service";

const Confirm = () => {
    const okMessage = "Impostazione avvenuta con successo.";
    const errMessage = "Qualcosa è andato storto";
    const [token, setToken] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confPassword, setConfPassword] = useState("");
    const [mess, setMess] = useState('');
    const [showForm, setShowForm] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confPassword) {
            setPassword('');
            setConfPassword('');
            setMess("Le due password non coicidono");
            return;
        }
        setShowForm(false);
        AuthService.setNewPassword(username, password, token)
        .then (response => {
            if (response.data) {
                if (response.data.isSuccess) {
                    setMess(okMessage);
                } else {
                    setShowForm(true);
                    setMess(response.data.message ? response.data.message : errMessage);
                }
            }
        })
        .catch (err => {
            setShowForm(true);
            setMess(err.data.message ? err.data.message : errMessage);
        });
        setPassword('');
        setConfPassword('');

    };



    const [searchParams, setSearchParams] = useSearchParams();
    
    useEffect (() => {
        let type = '';
        let user = '';
        let key = '';
        if (searchParams.get("t")) {
            type = searchParams.get("t");
        }
        if (searchParams.get("uid")) {
            user = searchParams.get("uid");
        }
        if (searchParams.get("token")) {
            key = searchParams.get("token");
        }

        if (type === 'p' && user && key) {
            setShowForm(true);
            setToken(key);
            setUsername(user);
        } else if ((type === 'm' || type === 't') && user && key) {
            AuthService.confirmToken (type, user, key)
            .then((esito) => {
                if(esito.data) {
                    if (esito.data.isSuccess) {
                        setMess(okMessage);
                    } else {
                        console.log("x");
                        setMess(esito.data.message ? esito.data.message : errMessage);
                    }
                }
            })
            .catch((err) => {
                setMess(err.data.message ? err.data.message : errMessage);
            });
        } else {
            setMess(errMessage);
        }
    }, []);
    
  return (
    <section>

    {showForm ? <>
        <p className={mess ? "errmsg" : "offscreen"} aria-live="assertive">{mess}</p>
        <h3>Inserisci la nuova password</h3>
        <form onSubmit={handleSubmit}>
            <label htmlFor="password">Password:</label>
            <input
                id="password"
                type="password"
                placeholder="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <label htmlFor="cfpwd">Conferma password:</label>
            <input
                id="cfpwd"
                type="password"
                placeholder="password"
                value={confPassword}
                onChange={(e) => setConfPassword(e.target.value)}
            />
            <button className="button-77" type="submit">Salva</button>
        </form>
    </>:<>
        <h3>{mess}</h3>
    </>}
    </section>
  );
};

export default Confirm;
