import axios from "axios";
import jwtDecode from "jwt-decode";
import appSettings from "../appsettings.json";

const API_URL = appSettings.SERVICE_URL + "access";
const API_REG_URL = appSettings.SERVICE_URL + "registration";

const domain = "luckysistemi";

const signup = (userdata) => {
  return axios
    .post(API_REG_URL, {
      ...userdata,
      Domain: domain,
    })
    .catch(err => {
      throw(err.response);

    })
};

async function confirmToken (type, user, token) {
  let serviceName = type === 'm' ? 'confirmemail' : type === 't' ? 'confirmphone' : '';
  return axios
    .post(API_REG_URL + "/" + serviceName, {
      UserName: user,
      Domain: domain,
      Password: token
    })
    .catch(err => {
      throw(err.response);

    })
};


async function setNewPassword (user, password, token) {
  return axios
    .post(API_REG_URL + "/resetpwd", {
      UserName: user,
      Password: password,
      Domain: domain,
      OTP: token
    })
    .catch(err => {
      throw (err.response);
    })
};


const resetPwd = (username) => {
  return axios
  .post(API_REG_URL + "/reqrestpwd", {
    Username: username,
    Domain: domain,
  }).then((response) => {
    if (response.data) {
      var AuthResponse = response.data;
      if (AuthResponse.isSuccess) {
        console.log(AuthResponse.isSuccess);
      } else {
        if (AuthResponse.message)
          throw(AuthResponse.message);
        else
          throw ("Errore login");
      }
      
    }
    return response.data.isSuccess;
  });

};

const login = (UserName, password, OTP) => {
  var esito = 0;
  return axios
    .post(API_URL + "/login", {
      Username: UserName,
      Password: password,
      Domain: domain,
      OTP: OTP
    })
    .then((response) => {
      if (response.data) {
        var AuthResponse = response.data;
        if (AuthResponse.isSuccess) {
          console.log(AuthResponse.isSuccess);
          var AuthResult = AuthResponse.payload;
          if (AuthResult.isFinal) {
            esito = 2;
            localStorage.setItem("user", JSON.stringify(AuthResult.token));
            //jwtDecode(JSON.stringify(response.data.payload.token)).nameid
          } else {
            esito = 1;
            console.log("Manca ancora un pezzo");
          }
        } else {
          esito = -1;
          console.log(AuthResponse.message);
          if (AuthResponse.message)
            throw(AuthResponse.message);
          else
            throw ("Errore login");
        }
        
      }


      return esito;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const authService = {
  signup,
  login,
  logout,
  getCurrentUser,
  resetPwd,
  setNewPassword,
  confirmToken,
};

export default authService;
