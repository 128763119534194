


const SinglePost = (props) => {
    const post = props.post;

  return (
    <div className='container__'>
        <div className="postheading">
            <h1>{post?.title}</h1>
            <small>{post?.postDate}</small>
        </div>
        <div className="postbody">
            <img src= {post?.image} />
            <p>{post?.description}</p>
        </div>
        <div className='postfooter'>
            <h5>{post?.author}</h5>
        </div>
      
      
    </div>
  )
}

export default SinglePost;