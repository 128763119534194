import { useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'

import axios from "axios";
import SinglePost from './SinglePost';
import appSettings from "../appsettings.json";

const API_URL = appSettings.SERVICE_URL + "post/";

const Post = (props) => {
  //this is where we will store data after getting from api
  const [post, setPost] = useState();
  // get id from route param using this hook
  const { id } = useParams();
  // then in useEffect call the api to fetch data for single post
  useEffect(()=>{
    axios.get(`${API_URL + id}`).then(res => {
   setPost(res.data);
   })
  },[])
  return (
    <SinglePost post = {post} />
  )
}

export default Post;