import React, { useState } from "react";
import AuthService from "../services/auth.service";
import { useNavigate } from "react-router-dom";

const Signup = () => {
  const okMessage = "Impostazione avvenuta con successo.";
  const errMessage = "Qualcosa è andato storto";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [errMsg, setErrMsg] = useState('');

  const regPhone = false;
  const regUsername = false;
  const regPassword = false;

  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    if (password !== confPassword) {
      setPassword('');
      setConfPassword('');
      setErrMsg("Le due password non coicidono");
      return;
    }
    await AuthService.signup({
      UserName: username,
      Password: password,
      Email: email,
      PhoneNr: phone
    })
    .then((response) => {
        // check for token and user already exists with 200
        //   console.log("Sign up successfully", response);
        //navigate("/home");
        //window.location.reload();

        if (response.data) {
          setErrMsg(response.data.message ? response.data.message : response.data.isSuccess ? okMessage : errMessage);
        }
    }).catch(err => {
      setErrMsg(err.data.message ? err.data.message : errMessage);
    });
    setPassword('');
    setConfPassword('');
  };

  return (
    <section>
      <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>

      <form onSubmit={handleSignup}>
        <h3>Sign up</h3>

        {regUsername && <>
          <label htmlFor="username">Username:</label>
          <input
            id="username"
            type="text"
            placeholder="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </>}

        <label htmlFor="email">Email:</label>
        <input
          id="email"
          type="text"
          placeholder="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        {regPhone && <>
          <label htmlFor="phone">Telefono:</label>
          <input
            id="phone"
            type="text"
            placeholder="telefono"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </>}
        
        {regPassword && <>
          <label htmlFor="password">Password:</label>
          <input
            id="password"
            type="password"
            placeholder="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <label htmlFor="confpassword">Password:</label>
          <input
            id="confpassword"
            type="password"
            placeholder="conferma password"
            value={confPassword}
            onChange={(e) => setConfPassword(e.target.value)}
          />
        </>}
        
        <button 
          type="submit"
          className="button-77"
          >Sign up</button>
      </form>
    </section>
  );
};

export default Signup;
