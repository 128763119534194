import i18next from "i18next";
import { initReactI18next } from "react-i18next";
// "Inline" English and Arabic translations.
// We can localize to any language and any number of languages.
const resources = {
  it: {
    translation: {
      app_name: "Grootbasket",
      btn_snd_rich: "Invia richiesta",
      lgn_login: "Login",
      lgn_btn_login: "Accedi",
      lgn_lnk_remember: "Password dimenticata ?",
      lgn_lnk_backlogin: "Torna al login",
      lgn_otp_req: "Codice OTP",
      lgn_otp_lbl: "OTP:",
      lgn_err_gen: "Errore al login",
      lgn_msg_success: "Controlla la tua mail",
      lgn_err_srv: "Richiesta fallita",
    },
  },
  en: {
    translation: {
      app_name: "pippo",
    },
  },
};
i18next
  .use(initReactI18next)
  .init({
    resources,
    lng: "it",
    interpolation: {
      escapeValue: false,
    },
  });
export default i18next;