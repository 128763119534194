

const PostCard = (props) => {
    const post = props.post;
    const postkey = props.postkey;

  return (
    <div className="card__" key={postkey}>
        <div className="card__header">
            <img src={post.image} alt="header"/>
        </div>
        <div className="card__body">
            <span className="tag tag-blue">{post.category}</span>
            <h4><a href={"post/" + post.id}>{post.title}</a></h4>
            <p>{post.description}</p>
        </div>
        <div className="card__footer">
            <div className="user">
                <img src="" className="user__image" />
                <div className="user__info">
                    <h5>{post.author}</h5>
                    <small>{post.postDate}</small>
                </div>
            </div>
        </div>

    </div>
  )
}

export default PostCard;