export default function authHeader() {
  const user = JSON.parse(localStorage.getItem("user"));
console.log(user);
  if (user) {
    // return { Authorization: 'Bearer ' + user.accessToken };
    return { "bearer": user };
    //return { "x-auth-token": user };
  } else {
    return {};
  }
}
