import PostCard from '../components/PostCard';


const PostCarousel = (props) => {
    const posts = props.posts;

  return (
    <div className="container__">
        {posts.map((post, index) => (
          <PostCard post={post} postkey={index} />
        ))}
    </div>
  )
}

export default PostCarousel;